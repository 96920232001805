import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import CollectionList from '~/components/Collection/List'

const CollectionsTemplate = ({ data: { lineup } }) => {
  return (
    <Layout>
      <Metadata
        title={lineup.name}
        description={`${lineup.name} lineup page`}
      />
      <CollectionList
        collections={lineup.collections}
        parentPath={lineup.slug}
      />
    </Layout>
  )
}

export const query = graphql`
  query LineupQuery($slug: String) {
    lineup: contentfulLineup(slug: { eq: $slug }) {
      ...LineupPageFragment
    }
  }
`

export default CollectionsTemplate
