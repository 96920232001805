/** @jsx jsx*/
import { Container, Box, Text, Heading, Button, Flex, jsx } from 'theme-ui'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router'

const CollectionCardAlternate = ({ parentPath, collection }) => {
  const { pathname } = useLocation()
  const basePath = pathname.split('/')[1]
  return (
    <Container variant="fullwidth">
      <Flex
        sx={{
          flexDirection: ['column-reverse', null, 'row'],
          justifyContent: 'space-between',
          alignItems: 'center',
          py: ['0 2rem', '2.5rem']
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            minWidth: [null, null, '33%'],
            margin: ['2rem auto 0 auto', null, '0 auto'],
            a: {
              fontWeight: '500 !important',
              fontFamily: 'body'
            }
          }}
        >
          <Heading
            as="h2"
            variant="h2"
            sx={{
              fontWeight: 'medium',
              textTransform: 'uppercase',
              textAlign: 'center',
              fontSize: ['2rem', '2.5rem', '2rem', '4rem'],
              mb: ['0.7rem']
            }}
          >
            {collection.title}
          </Heading>
          <Text
            sx={{
              marginBottom: ['3.4rem', null, '5rem', '6.5rem'],
              textAlign: 'center',
              fontSize: ['1.3rem', null, null, '2rem']
            }}
          >
            {collection.tagLine}
          </Text>
          <Button
            as={Link}
            variant="primary"
            to={`/${basePath}/${collection.slug}`}
            sx={{
              marginBottom: ['2.1rem', null, '2.5rem', '3.5rem'],
              height: '3.3rem',
              lineHeight: '3.3rem'
            }}
            aria-label={`Link to ${collection.title} collection`}
          >
            Shop Collection
          </Button>
          {collection.lookbook && (
            <Link
              to={`/${parentPath === 'collections' ? 'apparel' : parentPath}/${
                collection.slug
              }/lookbook`}
              sx={{ variant: 'text.link', fontSize: [1, 2, 3], color: 'black' }}
              aria-label={`Link to ${collection.title} lookbook`}
            >
              View Lookbook
            </Link>
          )}
        </Flex>
        <Box
          sx={{
            width: ['100%', null, '55rem', '65rem', '120rem'],
            minWidth: ['100%', null, '55rem', '65rem', '120rem'],
            mr: [0, null, '10rem']
          }}
        >
          <GatsbyImage
            image={collection?.mainImage?.gatsbyImageData}
            alt={
              collection?.mainImage?.description ?? collection?.mainImage?.title
            }
          />
        </Box>
      </Flex>
    </Container>
  )
}

export default CollectionCardAlternate
