/** @jsx jsx */
import { Box, Container, jsx } from 'theme-ui'
import CollectionCardAlternate from '~/components/Collection/Card/Alternate'

const CollectionList = ({ collections, parentPath }) => {
  // Extract nested collections that may come from nested Lineups
  const allCollections = collections.reduce(
    (acc, c) =>
      c.__typename === 'ContentfulLineup'
        ? [...acc, ...c.collections]
        : [...acc, c],
    []
  )

  return (
    <Container variant="fullwidth">
      <Box
        sx={{
          '& > div:not(:last-of-type)': { py: ['2rem', '2.5rem'] },
          '& > div:nth-of-type(2n)': {
            backgroundColor: [null, null, 'white', null]
          },
          '& > div:last-of-type': {
            pb: ['9rem', '6.5rem'],
            pt: ['2rem', '2.5rem']
          }
        }}
      >
        {allCollections.map(collection => (
          <CollectionCardAlternate
            key={collection.slug}
            parentPath={parentPath}
            collection={collection}
          />
        ))}
      </Box>
    </Container>
  )
}

export default CollectionList
